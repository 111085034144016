.container-image {
  background-image: url("../../../src/assets/imgs/varios/banner_deec_dark.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.custom-image {
  border-radius: 50px 0 50px 0px;
}
