.proyect-video {
  border-radius: 50px;
  cursor: pointer;
}

.thumbnail-video-circle {
  width: 7rem;
  height: 7rem;
  /*  aspect-ratio: 16 / 9; */
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  border: 0.2rem solid transparent;
  transition: border 0.3s ease, width 0.3s ease, height 0.3s ease;
}

.thumbnail-video-circle:hover {
  border-color: #f1f1ef;
}

.thumbnail-video.selected {
  border-color: #f1f1ef;
}
