.viewer-container {
  border-radius: 50px;
  overflow: hidden;
}

.pnlm-controls-container {
  top: 25% !important;
}

.image-thumbnails {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0;
  gap: 30px;
}

.thumbnail-image {
  flex: 1;
  height: 100px;
  object-fit: cover;
  border-radius: 30px;
  overflow: hidden;
  border: 2px solid transparent;
  transition: border 0.3s ease;
}

.thumbnail-image.selected {
  border-color: #f1f1ef;
}
