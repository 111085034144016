body {
  margin: 0;
  font-family: "Raleway", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1c1c1c;
  background-image: url("./assets/imgs/varios/TexturaO100p.png");
  background-size: contain;
  background-repeat: repeat;
  font-display: swap;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  color: #f1f1ef;
  line-height: 1.5;
}

h1,
h2,
h3 {
  color: #ffffff;
}

h4,
h5,
h6 {
  color: #ffffff;
}

.text-over-image {
  /*   text-shadow: 1px 1px #000000; */
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.685);
}

.text-over-image p {
  color: #ffffff;
}

label {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
}

/* Tipografía */

.raleway-regular {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.raleway-regular-italic {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: italic;
}

.raleway-medium {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.raleway-medium-italic {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: italic;
}

.raleway-semibold {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.raleway-semibold-italic {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: italic;
}

.raleway-bold {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.raleway-bold-italic {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: italic;
}

.raleway-light {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

.raleway-light-italic {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: italic;
}

.raleway-extralight {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}

.raleway-extralight-italic {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: italic;
}

.raleway-thin {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
}

.raleway-thin-italic {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: italic;
}

.raleway-extrabold {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}

.raleway-extrabold-italic {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: italic;
}

.raleway-black {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
}

.raleway-black-italic {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: italic;
}

/*Botones*/

.custom-btn-black {
  background-color: #000000 !important;
  color: #ffffff;
  border-color: #000000 !important;
}

.custom-btn-black:hover {
  background-color: #5f4539 !important;
  color: #ffffff;
  border-color: #5f4539 !important;
}

.custom-btn-light {
  background-color: #f1f1ef !important;
  color: #000000;
  border-color: #f1f1ef !important;
}

.custom-btn-light:hover {
  background-color: #5f4539 !important;
  color: #ffffff;
  border-color: #5f4539 !important;
}

.custom-btn-transparent {
  background-color: transparent;
  color: #ffffff;
}

.custom-btn-transparent:hover {
  background-color: #5f4539;
  color: #ffffff;
}

.custom-btn-transBlack {
  background-color: transparent;
  color: #ffffff;
}

.custom-btn-transBlack:hover {
  background-color: #000000;
  color: #ffffff;
}

.custom-btn-brown {
  background-color: #5f4539 !important;
  color: #ffffff;
  border-color: #5f4539 !important;
}

.custom-btn-brown:hover {
  background-color: #f1f1ef !important;
  color: #0f242d;
  border-color: #f1f1ef !important;
}

/*Indicadores carrousel*/

.p-carousel-indicator button.p-link {
  background-color: #f1f1ef;
}

.p-carousel-indicator.p-highlight button.p-link {
  background-color: #5f4539;
}

li {
  color: #f1f1ef;
}

li::marker {
  color: #5f4539;
  font-weight: bold;
}

.input-custom {
  width: 100%;
  border: none;
  border-radius: 0px;
  border-bottom: 2px solid #5f4539;
  box-sizing: border-box;
  background-color: transparent;
  color: #f1f1ef;
}

input:focus {
  outline: none;
  box-shadow: none;
}

textarea:focus {
  outline: none;
  box-shadow: none;
}

label {
  color: #f1f1ef;
}

.icono {
  width: 50px;
  height: 50px;
}
