.card {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #1c1c1cc2;
}

body {
  padding-top: 80px;
}

/* Eliminar el borde del Menubar */
.p-menubar,
.p-menubar-root {
  border: none !important;
  box-shadow: none !important;
}

.p-menubar .p-menuitem-icon {
  display: none !important;
}

.p-menubar .p-menuitem-content {
  background-color: transparent;
}

/* Quitar bordes internos o de los ítems */
.p-menubar .p-menubar-content .p-menuitem-link {
  border: none !important;
}

.p-menubar .p-menuitem-link {
  color: #ffffff !important;
}

/* Estilo para el texto de los items del menú */
.p-menubar .p-menuitem-text {
  color: #ffffff !important;
}

.p-menubar .p-menuitem-link:hover {
  background-color: #5f4539 !important;
  color: #ffffff !important;
  border-radius: 5px;
  transition: all 0.3s ease;
}

/* Estilos menú hamburguesa */
@media (max-width: 960px) {
  .p-menubar {
    z-index: 3000;
    outline: none;
  }

  .p-menubar-button:focus {
    outline: none;
    box-shadow: none;
  }

  .p-menubar-button {
    color: #ffffff;
  }

  .p-menubar-button:hover {
    background-color: #5f4539;
    color: #f1f1ef;
  }

  .p-menubar-root-list {
    background-color: #1c1c1c;
  }

  .p-menubar-root-list .p-menuitem-link {
    color: #ffffff;
  }

  .p-menubar-root-list .p-menuitem-link:hover {
    background-color: #5f4539;
    color: #f1f1ef;
  }
}
