.faqImage .faq-image-container {
  position: relative;
  overflow: hidden;
  border-radius: 30px;
}

.faqImage .image {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

/* Estilos Accordion */
.p-accordion {
  background-color: transparent;
  border: none;
}

.p-accordion-header {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #5f4539;
}

.p-accordion-header:focus {
  outline: none;
  box-shadow: none;
}

.p-accordion-header-text {
  color: #ffffff;
}

.p-accordion-header-link {
  background-color: transparent;
  border: none;
  color: #ffffff;
  padding: 5px;
  padding-bottom: 15px;
}

.p-accordion-header-link:focus {
  outline: none;
  box-shadow: none;
}

.p-accordion-content {
  background-color: transparent;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
}

.p-accordion-tab-content {
  background-color: transparent;
}

.p-accordion-tab {
  border: none;
}

.p-card {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.p-card-body {
  padding: 0;
}
