.Proyects {
  /* Add your component styles here */
}

.p-paginator {
  background: transparent;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  color: whitesmoke;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last:hover {
  background: transparent;
}

.p-paginator .p-paginator-current {
  color: whitesmoke;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  color: whitesmoke;
  border: 1px solid transparent;
  transition: border-color 0.3s ease-in-out;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: transparent;
  border-color: #5f4539 !important;
  color: whitesmoke;
}

.p-paginator .p-paginator-pages .p-paginator-page:hover {
  background: transparent;
  border-color: #5f4539 !important;
  color: whitesmoke;
  transition: border-color 0.3s ease-in-out;
}
