.FilterToolbarProyects {
  /* Add your component styles here */
}

.p-button-group .p-button {
  background: transparent;
  border: none;
  width: 25%;
  color: #f1f1ef;
}

.p-selectbutton {
  border: 2px solid #5f4539;
  border-radius: 20px;
}

.p-button:focus {
  border-radius: 20px;
}

.p-selectbutton .p-button.p-highlight {
  background: #5f4539;
  border-color: #5f4539;
  outline: none;
  box-shadow: none;
  color: #ffffff;
  border-radius: 20px;
}
