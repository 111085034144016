.image-custom5 {
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.image-container8 {
  border-top-right-radius: 150px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  max-width: 500px;
  min-height: 12rem;
  max-height: 12rem;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #5f4539;
}
