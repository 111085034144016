.CardProyect {
  /* Add your component styles here */
}

.CardProyectContainer {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

button:focus {
  outline: none;
  box-shadow: none;
}
