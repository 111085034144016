.SteppersCard {
}

.imageContainerImpar {
  border-radius: 0 100px 0 100px;
}

.imageContainerPar {
  border-radius: 100px 0 100px 0;
}

.imageContainer {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.indice {
  color: #5f4539;
}

@media screen and (min-width: 1200px) {
  .imageContainerImpar {
    border-radius: 100%;
  }

  .imageContainerPar {
    border-radius: 100%;
  }
}
