/* Estilos iniciales */
.CardTemplateSummaryProyects {
  border-radius: 50px;
  transition: background-color 0.3s ease, border-radius 0.3s ease,
    transform 0.3s ease;
  transform-origin: center; /* Centro como punto de escala */
}

.CardTemplateImage {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 30px 30px 0 0;
  transition: border-radius 0.3s ease;
}

.CardTemplateImage span {
  background-color: rgba(28, 28, 28, 0.5);
  transition: background-color 0.3s ease, border 0.3s ease;
}

.CardTemplateSummaryProyects p {
  transition: color 0.3s ease;
}

.CardTemplateSummaryProyects:hover {
  background-color: whitesmoke;
  transform: scale(0.98);
}

.CardTemplateSummaryProyects:hover .CardTemplateImage span {
  border: solid 1px whitesmoke;
}

.CardTemplateSummaryProyects:hover p {
  color: black;
}
