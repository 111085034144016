/* Por defecto (pantallas pequeñas), el texto ocupa una sola columna */
.responsive-columns {
  column-count: 1;
}

/* Pantallas medianas (min-width: 768px) */
@media screen and (min-width: 768px) {
  .responsive-columns {
    column-count: 2;
    column-gap: 20px; /* Espacio entre columnas */
  }
}
