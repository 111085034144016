swiper-container {
  width: 100%;
  height: 18rem;
}

swiper-slide {
  display: flex;
  background-position: center;
  background-size: cover;
  width: 30%;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.568);
}

swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.seccion {
  position: absolute;
  z-index: 2000;
  pointer-events: none;
}

/* Custom Swiper styles */
:root {
  --swiper-pagination-color: white;
  --swiper-pagination-bullet-inactive-color: white;
  --swiper-pagination-bullet-inactive-opacity: 0.5;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-navigation-color: white;
}

.mySwiper .swiper-pagination-bullet {
  background-color: var(--swiper-pagination-color);
}

.mySwiper .swiper-pagination-bullet-active {
  background-color: var(--swiper-pagination-color);
}

.mySwiper .swiper-button-next,
.mySwiper .swiper-button-prev {
  color: var(--swiper-navigation-color);
}
