.social i,
.copy {
  color: #5f4539;
}

a {
  text-decoration: none; /* Quita el subrayado */
  color: inherit; /* Hereda el color del texto padre */
}

a:hover {
  color: #5f4539; /* Añade subrayado al pasar el mouse (opcional) */
}
