.container-image2 {
  background-image: url("../../../src/assets/imgs/Proyects/Casa2/Estaticas/Casa2_3.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.icono {
  width: 50px;
  height: 50px;
}

.custom-image-2 {
  border-radius: 0 50px 0 50px;
}

.container-info-portada-2{
  background-color: rgba(0, 0, 0, 0.5);
}