.photo-container {
  border-radius: 50px;
  overflow: hidden;
}

.selected-image {
  width: 100%;
  height: 22rem;
  object-fit: cover;
  padding: 0;
}

.image-thumbnails-circle {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0;
  gap: 1.5rem;
}

.thumbnail-image-circle {
  width: 7rem;
  height: 7rem;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: 50%;
  border: 0.2rem solid transparent;
  transition: border 0.3s ease, width 0.3s ease, height 0.3s ease;
}

/* Estilo cuando una miniatura es seleccionada */
.thumbnail-image-circle.selected {
  border-color: #f1f1ef;
}

/* Estilos del modal */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 3000;
}

.modal-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  overflow: hidden;
}

.full-screen-image {
  max-width: 95vw;
  max-height: 95vh;
  object-fit: cover;
  border-radius: 20px;
  cursor: pointer;
}

/* Botón de cerrar modal */
.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #5f4539;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  font-size: 1.2rem;
  transition: background-color 0.3s ease;
}

.close-modal:hover {
  background-color: #f1f1ef;
  color: #5f4539;
}

.close-modal:focus {
  outline: none;
  box-shadow: none;
}
