.image-custom1 {
  border-top-left-radius: 150px;
  border-top-right-radius: 0;
  overflow: hidden;
  border-color: #5f4539;
}

.image-custom2 {
  border-top-right-radius: 150px;
  border-top-left-radius: 0;
  overflow: hidden;
  border-color: #5f4539;
}

.image-container6 {
  width: 100%;
  height: 25rem;
  max-width: 500px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-custom-base {
  height: 100%;
  width: auto;
  object-fit: cover;
}
